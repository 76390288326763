import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { generatePath } from 'react-router-dom'
import styled, { css } from 'styled-components'
import ROUTE from '@enums/ROUTE'
import useGlobalContext from '@hooks/useGlobalContext'
import MODALS from '@enums/MODALS'
import Link from '@components/Link'
import RatingSuccessImage from '@assets/images/ratingsuccess.svg'
import { ButtonAttributes } from '@StyledComponents/Buttons'
import { breakpointSmallPlusAndBelow } from '@components/StyledComponents/theme/helpers/breakpoints'
import useUserInfo from '@hooks/useUserInfo'

const SuccessContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const ImageComponent = styled.div`
  background-image: url(${RatingSuccessImage});
  background-position: center;
  background-repeat: no-repeat;
  min-width: 402px;
  width: 100%;
`

const SuccessContentContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 160px 82px 100px;
  width: 662px;
  ${breakpointSmallPlusAndBelow(css`
    padding: 110px 15px 60px;
    width: 402px;
  `)}
`

const SuccessHeading = styled.div`
  font-size: 20px;
  font-weight: ${({ theme }) => theme.fontWeight.bolder};
  line-height: 24px;
  margin-bottom: 16px;
  ${breakpointSmallPlusAndBelow(css`
    margin-bottom: 12px;
  `)}
`

const SuccessSubHeading = styled.div`
  font-size: ${({ theme }) => theme.fontSize.medium};
  line-height: 22px;
  margin-bottom: 16px;
  ${breakpointSmallPlusAndBelow(css`
    margin-bottom: 12px;
    font-size: ${({ theme }) => theme.fontSize.default};
  `)}
`

const StyledLegalLink = styled(Link).attrs(props => ({ ...props, target: '_blank' }))`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`

const SuccessCTAButton = styled(Link)`
  ${ButtonAttributes}
  padding-left: 64px;
  padding-right: 64px;
  text-decoration: none;
`

const AddSuccessHeader = ({
  headerData,
  isAddCampus,
  isAddProfessor,
  isSubmitProfessorCorrection,
  isSubmitCampusCorrection
}) => {
  const userInfo = useUserInfo()
  const { setActiveModal, activeModal } = useGlobalContext()
  useEffect(() => {
    if (!userInfo && !activeModal) {
      setActiveModal(MODALS.REGISTER_PROMPT)
    }
  }, [userInfo])

  return (
    <SuccessContainer>
      <ImageComponent>
        <SuccessContentContainer>
          {isAddProfessor && (
            <React.Fragment>
              <SuccessHeading>
                Thanks for adding {`Professor ${headerData.profLastName}`}!
              </SuccessHeading>

              <SuccessSubHeading>
                We&apos;ve added your professor! However, the profile page is under review to ensure
                that it meets our{' '}
                <StyledLegalLink to={ROUTE.LEGAL_SITE_GUIDELINES}>Site Guidelines.</StyledLegalLink>
              </SuccessSubHeading>
              <SuccessCTAButton
                to={generatePath(ROUTE.NEW_ADD_RATING_TEACHER, { tid: headerData.profLegacyId })}
                id="rate-professor-btn"
              >
                Rate Professor {headerData.profLastName}
              </SuccessCTAButton>
            </React.Fragment>
          )}

          {isAddCampus && (
            <React.Fragment>
              <SuccessHeading>Thanks for adding {headerData.campusName}!</SuccessHeading>

              <SuccessSubHeading>
                We&apos;ve added your school! However, the profile page is under review to ensure
                that it meets our{' '}
                <StyledLegalLink to={ROUTE.LEGAL_SITE_GUIDELINES}>Site Guidelines.</StyledLegalLink>
              </SuccessSubHeading>

              <SuccessCTAButton to={`${ROUTE.NEW_ADD_PROFESSOR}`} id="rate-professor-btn">
                Add a Professor
              </SuccessCTAButton>
            </React.Fragment>
          )}

          {isSubmitProfessorCorrection && (
            <React.Fragment>
              <SuccessHeading>
                Thanks for correcting {`Professor ${headerData.profLastName}`}!
              </SuccessHeading>

              <SuccessSubHeading>
                We&apos;ve received your correction! However, the correction is under review to
                ensure that it meets our{' '}
                <StyledLegalLink to={ROUTE.LEGAL_SITE_GUIDELINES}>Site Guidelines.</StyledLegalLink>
              </SuccessSubHeading>

              <SuccessCTAButton
                to={generatePath(ROUTE.NEW_ADD_RATING_TEACHER, { tid: headerData.profLegacyId })}
                id="rate-professor-btn"
              >
                Rate Professor {headerData.profLastName}
              </SuccessCTAButton>
            </React.Fragment>
          )}

          {isSubmitCampusCorrection && (
            <React.Fragment>
              <SuccessHeading>Thanks for correcting {headerData.schoolName}!</SuccessHeading>

              <SuccessSubHeading>
                We&apos;ve received your correction! However, the correction is under review to
                ensure that it meets our{' '}
                <StyledLegalLink to={ROUTE.LEGAL_SITE_GUIDELINES}>Site Guidelines.</StyledLegalLink>
              </SuccessSubHeading>

              <SuccessCTAButton
                to={generatePath(ROUTE.NEW_ADD_RATING_SCHOOL, {
                  sid: headerData?.schoolLegacyId ?? 0
                })}
                id="rate-school-btn"
              >
                Rate {headerData.schoolName}
              </SuccessCTAButton>
            </React.Fragment>
          )}
        </SuccessContentContainer>
      </ImageComponent>
    </SuccessContainer>
  )
}

AddSuccessHeader.propTypes = {
  headerData: PropTypes.shape([
    PropTypes.shape({
      profLastName: PropTypes.string,
      campusName: PropTypes.string
    })
  ]),
  isAddCampus: PropTypes.bool,
  isAddProfessor: PropTypes.bool,
  isSubmitProfessorCorrection: PropTypes.bool,
  isSubmitCampusCorrection: PropTypes.bool
}

export default AddSuccessHeader
