const ROUTE = {
  SHOW_RATINGS: '/professor/:tid(\\d+)',
  NEW_CAMPUS_RATINGS: '/school/:sid(\\d+)',
  TEACHER_NOT_FOUND: '/teacher-not-found',
  CAMPUS_NOT_FOUND: '/school-not-found',
  RATING_NOT_FOUND: '/rating-not-found',
  NEW_ADD_RATING_TEACHER: '/add/professor-rating/:tid(\\d+)',
  NEW_EDIT_RATING_TEACHER: '/edit/professor-rating/:tid/:rid?',
  ADD_PROFESSOR_RATING_SUCCESS: '/add/professor-rating/success/:sid?',
  ADD_CAMPUS_RATING_SUCCESS: '/add/school-rating/success/:sid',
  ADD_PROFESSOR_SUCCESS: '/add/professor/success',
  ADD_CAMPUS_SUCCESS: '/add/school/success',
  NEW_ADD_RATING_SCHOOL: '/add/school-rating/:sid(\\d+)',
  NEW_EDIT_RATING_SCHOOL: '/edit/school-rating/:sid/:rid?',
  ADD_SCHOOL: '/tempschool/addSchool',
  ADD_TEACHER: '/AddTeacher.jsp',
  NEW_ADD_PROFESSOR: '/add/professor',
  NEW_ADD_CAMPUS: '/add/school',
  PROFESSOR_SIGN_UP: '/signup/professor',
  STUDENT_SIGN_UP: '/signup',
  CCPA: '/ccpa',
  LEGAL_OLD_RMP: '/TermsOfUse_us.jsp',
  LEGAL_OLD_RMP_UTILITY: '/utility/terms',
  LEGAL_OLD_RMP_AD_CHOICES: '/%20utility/terms',
  LEGAL_TERMS: '/terms-of-use',
  LEGAL_PRIVACY: '/privacy',
  LEGAL_ADCHOICES: '/privacy#sectionp5',
  LEGAL_SITE_GUIDELINES: '/guidelines',
  LEGAL_COPYRIGHT: '/copyright',
  PAGE_NOT_FOUND: '/404',
  EDIT_MY_INFO: '/member/editAccount.jsp',
  EDIT_RATING: '/member/EditRating.jsp',
  PROF_EDIT_MY_INFO: '/member/profEditAccount.jsp',
  LOGIN: '/member',
  SIGN_UP: '/join.jsp',
  LOGOUT: '/member/logout.jsp',
  HELP: 'https://help.ratemyprofessors.com/',
  ABOUT: '/About.jsp',
  NEW_FLAG_PROF_RATING: '/flag/professor-rating/:tid/:rid',
  FLAG_PROFESSOR_NOTE: '/flagRebuttal.jsp',
  NEW_FLAG_CAMPUS_RATING: '/flag/school-rating/:sid/:rid',
  SUBMIT_CORRECTION: '/submitCorrection.jsp',
  SCHOOL_CORRECTION: '/schoolCorrection.jsp',
  ADD_SCHOOL_RATING: '/campusrating/create',
  UI_SANDBOX: '/ui-sandbox',
  USER_ACCOUNT: '/account',
  RESET_PASSWORD: '/reset-password',
  FORGOT_PASSWORD: '/forgot-password',
  TEACHER_SEARCH: '/search/professors/:sid(\\d+)',
  TEACHER_SEARCH_ALL: '/search/professors/',
  OLD_TEACHER_SEARCH: '/search.jsp',
  SCHOOL_SEARCH: '/search/schools',
  CONFIRM_EMAIL: '/confirm-email',
  NEW_LOGIN: '/login',
  NEW_LOGOUT: '/logout',
  NEW_SIGN_UP: '/sign-up',
  HOME: '/',
  ERROR: '/error',
  COMPARE_SCHOOLS: '/compare/schools/:primaryId?/:secondaryId?',
  COMPARE_PROFESSORS: '/compare/professors/:primaryId?/:secondaryId?',
  COMPARE_BLANK: '/compare',
  RATING: '/rating/:rid(\\d+)',
  /**
   * The below routes are old routes. See comment in routeConfig.js
   */
  OLD_LOCKHART_PROFESSOR_RATINGS: '/ShowRatings.jsp',
  OLD_RMP_CAMPUS_RATINGS: '/campusRatings.jsp',
  OLD_LOCKHART_CAMPUS_RATINGS: '/ShowSchoolRatings',
  OLD_RMP_ADD_RATING_TEACHER: '/AddRating.jsp',
  OLD_LOCKHART_ADD_RATING_TEACHER: '/RateTeacher',
  OLD_LOCKHART_ADD_RATING_SCHOOL: '/RateSchool',
  OLD_RMP_FLAG_CAMPUS_RATING: '/flagCampusRating.jsp',
  OLD_RMP_FLAG_PROFESSOR_RATING: '/flagTeacherRating.jsp'
}

export const USER_ACCOUNT_ROUTE = {
  PROFILE: '/account/profile',
  SETTINGS: '/account/settings',
  SAVED_PROFESSORS: '/account/saved-professors',
  RATINGS: '/account/ratings'
}

export default ROUTE
