import React from 'react'
import styled, { css } from 'styled-components'
import { breakpointSmediumAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import { matchPath, useHistory } from 'react-router-dom'
import ROUTE from '@enums/ROUTE'
import MODALS from '@enums/MODALS'
import { StyledButton, StyledLink } from '@components/ModalButtonLink'
import { poppinsBoldest3x } from '@StyledComponents/theme/typesets'
import routeConfigs from '@src/config/routeConfig'
import FullPageModalContainer from './Templates/FullPageModal'

export const StyledMaintenanceModal = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 43px 0 48px 0;
  width: 756px;

  ${breakpointSmediumAndBelow(
    css`
      width: 345px;
    `
  )}

  ${StyledButton},
  ${StyledLink} {
    cursor: pointer;
    font-size: ${props => props.theme.fontSize.medium};
    font-weight: ${props => props.theme.fontWeight.bold};
    text-decoration: underline;
  }

  a {
    font-weight: ${props => props.theme.fontWeight.bold};
  }
`

export const MaintenanceModalHeaderArea = styled.div`
  margin-bottom: 18px;
  max-width: 100%;
  width: 440px;

  ${breakpointSmediumAndBelow(
    css`
      margin-bottom: 8px;
      width: 300px;
    `
  )}
`
export const MaintenanceModalHeader = styled.h1`
  margin-bottom: 12px;
  text-align: center;

  ${poppinsBoldest3x};

  ${breakpointSmediumAndBelow(
    css`
      font-size: ${props => props.theme.fontSize.xlarge};
    `
  )}
`
export const MaintenanceModalSubHeader = styled.h2`
  font-family: ${props => props.theme.fontFamily.helveticaNeue};
  font-size: ${props => props.theme.fontSize.medium};
  text-align: center;

  ${breakpointSmediumAndBelow(
    css`
      font-size: ${props => props.theme.fontSize.default};
      line-height: 22px;
    `
  )}
`

export default function MaintenanceModal() {
  const history = useHistory()
  const hideCloseButton = () => {
    const currentRoute = routeConfigs.find(rc => matchPath(history.location.pathname, rc))
    return (
      (currentRoute &&
        (currentRoute.path === ROUTE.NEW_ADD_RATING_SCHOOL ||
          currentRoute.path === ROUTE.NEW_ADD_RATING_TEACHER)) ||
      false
    )
  }
  return (
    <FullPageModalContainer
      modal={MODALS.MAINTENANCE_ANNOUNCEMENT}
      hideCloseButton={hideCloseButton()}
    >
      <StyledMaintenanceModal>
        <MaintenanceModalHeaderArea>
          <MaintenanceModalHeader>Scheduled Maintenance</MaintenanceModalHeader>
          <MaintenanceModalSubHeader>
            This action is unavailable while we complete scheduled maintenance, please try again
            later.
          </MaintenanceModalSubHeader>
          <MaintenanceModalSubHeader>Thank you for your patience.</MaintenanceModalSubHeader>
        </MaintenanceModalHeaderArea>
      </StyledMaintenanceModal>
    </FullPageModalContainer>
  )
}
