import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { createFragmentContainer, graphql } from 'react-relay'
import ROUTE from '@enums/ROUTE'
import { breakpointSmallPlusAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import Link, { LINK_TYPE } from '@components/Link'
import IconButton from '@StyledComponents/IconButton'
import RatingTypes from '@types/Rating.types'
import TeacherTypes from '@types/Teacher.types'
import { poppinsBoldMedium, helveticaBold } from '@StyledComponents/theme/typesets'
import { generatePath } from 'react-router-dom/cjs/react-router-dom.min'

const SuperHeaderContainer = styled.div`
  padding: 28px 24px 0;
`
export const SuperHeaderBody = styled.div`
  border-bottom: 1px solid black;
  display: flex;
  height: 100%;
  justify-content: ${props => (props.hideTitle ? 'flex-end' : 'space-between')};
  padding: 0 0 10px;
  width: 100%;
`
export const NamesContainer = styled.div`
  align-items: center;
  display: ${props => (props.hideTitle ? 'none' : 'flex')};
  line-height: 24px;
  ${breakpointSmallPlusAndBelow(css`
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
  `)}
`
export const TeacherName = styled.div`
  ${poppinsBoldMedium}
`
export const NameSeparator = styled.div`
  margin: 0 3px 0 5px;
  ${poppinsBoldMedium}
  ${breakpointSmallPlusAndBelow(css`
    display: none;
  `)}
`
export const SchoolName = styled.div`
  font-family: ${props => props.theme.fontFamily.poppins};
  font-size: ${props => props.theme.fontSize.medium};
  margin-left: 2px;
  ${breakpointSmallPlusAndBelow(css`
    margin-left: 0;
  `)}
`

export const StyledEditButton = styled(IconButton.Edit)`
  display: flex;
  font-size: ${props => props.theme.fontSize.default};
  justify-content: space-between;
  width: 64px;

  ${helveticaBold};
`

export const StyledEditLink = styled(Link)`
  text-decoration: none;
`

export function RatingSuperHeader({ hideTitle, rating, teacher }) {
  const editRoute = generatePath(ROUTE.NEW_EDIT_RATING_TEACHER, {
    tid: teacher.legacyId,
    rid: rating.legacyId
  })
  const linkType = LINK_TYPE.INTERNAL
  return (
    <SuperHeaderContainer>
      <SuperHeaderBody hideTitle={hideTitle}>
        <NamesContainer hideTitle={hideTitle}>
          <TeacherName>
            {teacher.firstName} {teacher.lastName}
          </TeacherName>
          <NameSeparator>&bull;</NameSeparator>
          <SchoolName>{teacher.school.name}</SchoolName>
        </NamesContainer>
        <StyledEditLink to={editRoute} type={linkType}>
          <StyledEditButton>Edit</StyledEditButton>
        </StyledEditLink>
      </SuperHeaderBody>
    </SuperHeaderContainer>
  )
}

export default createFragmentContainer(RatingSuperHeader, {
  teacher: graphql`
    fragment RatingSuperHeader_teacher on Teacher {
      firstName
      lastName
      legacyId
      school {
        name
      }
    }
  `,
  rating: graphql`
    fragment RatingSuperHeader_rating on Rating {
      legacyId
    }
  `
})

RatingSuperHeader.propTypes = {
  rating: RatingTypes,
  teacher: TeacherTypes,
  hideTitle: PropTypes.bool
}
