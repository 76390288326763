import React from 'react'
import styled from 'styled-components'
import ReCAPTCHA from '@lib/ReCAPTCHA'
import PropTypes from 'prop-types'
import { generatePath, withRouter } from 'react-router-dom'
import ROUTE from '@enums/ROUTE'
import { ButtonAttributes } from '@StyledComponents/Buttons'
import Link from '@components/Link'
import FormRecaptcha3Button from '@components/Form/FormReCaptcha3Button'

const StyledFormRecaptchaButton = styled(FormRecaptcha3Button)`
  ${ButtonAttributes}
  font-size: 16px;
  height: unset;
  margin: auto;
  padding: 9px 66px;
  width: unset;
  &:disabled {
    &:hover {
      background: ${({ theme }) => theme.color.fadedDefault};
    }
  }
  &:hover {
    background: ${({ theme }) => theme.color.gray8};
  }
`

const CancelContainer = styled.div`
  margin-top: 14px;
`

const StyledCancelButton = styled(Link)`
  font-size: 16px;
  line-height: 20px;
  margin-top: 5px;
  text-decoration: none;
`
export function FlagCampusSubmit({ schoolID }) {
  return (
    <div>
      <StyledFormRecaptchaButton action={ReCAPTCHA.ACTIONS.FLAG_RATING}>
        Submit
      </StyledFormRecaptchaButton>
      <CancelContainer>
        <StyledCancelButton to={generatePath(ROUTE.NEW_CAMPUS_RATINGS, { sid: schoolID })}>
          Cancel
        </StyledCancelButton>
      </CancelContainer>
    </div>
  )
}

FlagCampusSubmit.propTypes = {
  schoolID: PropTypes.number
}

export default withRouter(FlagCampusSubmit)
