import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { breakpointLargePlusAndBelow } from '@components/StyledComponents/theme/helpers/breakpoints'
import AdLeaderboard from '@components/Ads/AdLeaderboard'
import TeacherTypes from '@types/Teacher.types'
import SchoolTypes from '@types/School.types'
import HeaderDescription from '@components/StickyHeader/HeaderDescription'
import { createFragmentContainer, graphql } from 'react-relay'
import SEARCH_ITEM_TYPES from '@enums/SEARCHITEMTYPES'
import SearchResultsHeaderText from '@pages/Search/SearchResultsHeaderText'
import TeacherFilterSet from '@components/TeacherFilterSet'

const StyledLeftColumn = styled.div`
  font-size: 54px;
  width: 100%;
  text-align: left;
`

const StyledRightColumn = styled.div`
  width: 100%;
  text-align: right;

  ${breakpointLargePlusAndBelow(css`
    display: none;
  `)}
`

const StickyHeaderContent = ({
  teacher,
  large,
  school,
  searchPage,
  showButtons = true,
  query,
  ...props
}) => {
  const {
    itemType,
    filter,
    filters,
    setFilter,
    successPageData,
    count,
    schoolTeacherNoMatch
  } = props

  return (
    <React.Fragment>
      <StyledLeftColumn>
        {(teacher || school) && !searchPage && (
          <HeaderDescription
            teacher={teacher}
            large={large}
            school={school}
            showButtons={showButtons}
          />
        )}
        {searchPage && (
          <React.Fragment>
            <SearchResultsHeaderText
              filter={filter}
              query={query}
              school={school}
              count={count}
              itemType={itemType}
              schoolTeacherNoMatch={schoolTeacherNoMatch}
              successPageData={successPageData}
            />
            {filters && (
              <TeacherFilterSet
                setFilter={setFilter}
                filter={filter}
                filters={filters}
                options={filters?.options}
              />
            )}
          </React.Fragment>
        )}
      </StyledLeftColumn>
      <StyledRightColumn>
        <AdLeaderboard code="ad_lb_top_ratings" schoolHeader />
      </StyledRightColumn>
    </React.Fragment>
  )
}

const filterPropTypes = PropTypes.shape({
  field: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({ count: PropTypes.number, value: PropTypes.string }))
})

StickyHeaderContent.propTypes = {
  teacher: TeacherTypes,
  large: PropTypes.bool,
  school: SchoolTypes,
  searchPage: PropTypes.bool,
  showButtons: PropTypes.bool,
  itemType: PropTypes.oneOf(Object.values(SEARCH_ITEM_TYPES)),
  filter: filterPropTypes,
  filters: PropTypes.arrayOf(filterPropTypes),
  setFilter: PropTypes.func,
  successPageData: PropTypes.oneOfType([
    PropTypes.shape({
      teacher: TeacherTypes
    }),
    PropTypes.shape({
      school: SchoolTypes
    })
  ]),
  count: PropTypes.number,
  query: PropTypes.shape({
    text: PropTypes.string,
    schoolID: PropTypes.string,
    fallback: PropTypes.bool,
    departmentID: PropTypes.string
  }),
  schoolTeacherNoMatch: PropTypes.bool
}

export default createFragmentContainer(StickyHeaderContent, {
  teacher: graphql`
    fragment StickyHeaderContent_teacher on Teacher {
      ...HeaderDescription_teacher
      ...HeaderRateButton_teacher
    }
  `,
  school: graphql`
    fragment StickyHeaderContent_school on School {
      name
      ...HeaderDescription_school
      ...HeaderRateButton_school
    }
  `
})
