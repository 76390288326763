import React from 'react'
import styled from 'styled-components'
import { createFragmentContainer, graphql } from 'react-relay'
import { generatePath } from 'react-router-dom'
import TRACKING_EVENT from '@enums/TRACKING_EVENT'
import Link from '@src/components/Link'
import ROUTE from '@enums/ROUTE'
import TeacherTypes from '@types/Teacher.types'
import parseAndSanitize from '@utils/parseAndSanitize'
import { poppinsBoldest } from '@StyledComponents/theme/typesets'

export const TeacherScoreSpan = styled.span`
  align-items: center;
  background: ${props => props.theme.color.mainBlue};
  color: ${props => props.theme.color.gray};
  display: flex;
  font-size: ${props => props.theme.fontSize.medium};
  justify-content: center;
  margin-right: 8px;
  padding: 10px 7px;

  ${poppinsBoldest};
`

// Needed for use below to change color on hover
export const TeacherNameSpan = styled.span`
  text-align: left;
`

export const StyledSimilarProfessorLink = styled(Link)`
  align-items: center;
  display: flex;
  padding-right: 5px;
  text-decoration: none;

  &:hover ${TeacherNameSpan} {
    color: ${props => props.theme.color.darkMainBlue};
  }

  &:hover ${TeacherScoreSpan} {
    background: ${props => props.theme.color.darkMainBlue};
  }
`

export function SimilarProfessorListItem({ teacher }) {
  const professorPath = generatePath(ROUTE.SHOW_RATINGS, { tid: teacher.legacyId })
  return (
    <li>
      <StyledSimilarProfessorLink
        to={professorPath}
        trackingLabel={TRACKING_EVENT.SIMILAR_PROF_CLICK}
      >
        <TeacherScoreSpan>{teacher.avgRating.toFixed(2)}</TeacherScoreSpan>
        <TeacherNameSpan>
          {parseAndSanitize(`${teacher.firstName} ${teacher.lastName}`)}
        </TeacherNameSpan>
      </StyledSimilarProfessorLink>
    </li>
  )
}

SimilarProfessorListItem.propTypes = {
  teacher: TeacherTypes
}

export default createFragmentContainer(SimilarProfessorListItem, {
  teacher: graphql`
    fragment SimilarProfessorListItem_teacher on RelatedTeacher {
      legacyId
      firstName
      lastName
      avgRating
    }
  `
})
