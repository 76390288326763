import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { generatePath } from 'react-router-dom'
import { createFragmentContainer, graphql } from 'react-relay'
import ROUTE from '@enums/ROUTE'
import SchoolTypes from '@types/School.types'
import SchoolRatingTypes from '@types/SchoolRating.types'
import { LINK_TYPE } from '@components/Link'
import { poppinsBoldMedium } from '@StyledComponents/theme/typesets'
import {
  SuperHeaderBody,
  StyledEditButton,
  StyledEditLink
} from '@components/Rating/RatingSuperHeader'

const SuperHeaderContainer = styled.div`
  padding: 28px 24px 0;
`
const NamesContainer = styled.div`
  display: ${props => (props.hideTitle ? 'none' : 'flex')};
`
const SchoolName = styled.div`
  ${poppinsBoldMedium}
`

export function SchoolRatingSuperHeader({ hideTitle, rating, school }) {
  const editRoute = generatePath(ROUTE.NEW_EDIT_RATING_SCHOOL, {
    sid: school.legacyId,
    rid: rating.legacyId
  })
  const linkType = LINK_TYPE.INTERNAL
  return (
    <SuperHeaderContainer>
      <SuperHeaderBody hideTitle={hideTitle}>
        <NamesContainer hideTitle={hideTitle}>
          <SchoolName>{school.name}</SchoolName>
        </NamesContainer>
        <StyledEditLink to={editRoute} type={linkType}>
          <StyledEditButton>Edit</StyledEditButton>
        </StyledEditLink>
      </SuperHeaderBody>
    </SuperHeaderContainer>
  )
}

export default createFragmentContainer(SchoolRatingSuperHeader, {
  school: graphql`
    fragment SchoolRatingSuperHeader_school on School {
      name
      legacyId
    }
  `,
  rating: graphql`
    fragment SchoolRatingSuperHeader_rating on SchoolRating {
      legacyId
    }
  `
})

SchoolRatingSuperHeader.propTypes = {
  rating: SchoolRatingTypes,
  school: SchoolTypes,
  hideTitle: PropTypes.bool
}
