import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import { generatePath } from 'react-router-dom'
import styled, { css } from 'styled-components'
import CardNumRating, { StyledCardNumRating } from '@StyledComponents/CardNumRating'
import Link, { LINK_TYPE } from '@components/Link'
import ROUTE from '@enums/ROUTE'
import SchoolTypes from '@types/School.types'
import { breakpointSmallAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import SchoolCardHeader from './SchoolCardHeader'
import SchoolCardInfo from './SchoolCardInfo'
import SchoolCardLocation from './SchoolCardLocation'

export const StyledSchoolCard = styled(Link)`
  align-items: center;
  background: ${({ theme }) => theme.color.gray7};
  display: flex;
  padding: 14px 27px;
  position: relative;
  text-decoration: none;
  width: 100%;

  ${breakpointSmallAndBelow(
    css`
      flex-direction: column;
      padding: 26px 20px 20px 16px;

      ${StyledCardNumRating} {
        margin: 0 0 6px 0;
      }
    `
  )}

  ${StyledCardNumRating} {
    margin: 0 42px 0 0;
  }
`

const HeaderInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const CardNumRatingWrapper = styled.div`
  ${breakpointSmallAndBelow(
    css`
      width: 100%;

      ${StyledCardNumRating} {
        margin: 0 0 6px 0;
        width: fit-content;
      }
    `
  )}
`

export const SchoolCard = ({ school }) => {
  if (!school?.legacyId || !school?.name) return null
  const { avgRating, avgRatingRounded, numRatings } = school

  const generalRating = avgRatingRounded || avgRating
  const cardNumRatingConfig = {
    topText: 'QUALITY',
    numRating: generalRating || 0,
    bottomText: `${numRatings || '0'} ratings`
  }
  const schoolPath = generatePath(ROUTE.NEW_CAMPUS_RATINGS, { sid: school.legacyId ?? 0 })

  return (
    <StyledSchoolCard
      to={schoolPath}
      type={LINK_TYPE.INTERNAL}
      aria-label={`Link to school page for ${school.name}`}
    >
      <CardNumRatingWrapper>
        <CardNumRating config={cardNumRatingConfig} />
      </CardNumRatingWrapper>

      <HeaderInfoWrapper>
        <SchoolCardHeader school={school} />
        <SchoolCardLocation school={school} />
        {/* TODO: Leave this as a blank array until we can decide on what data should be displayed */}
        <SchoolCardInfo infoArray={[]} name={school.name} />
      </HeaderInfoWrapper>
    </StyledSchoolCard>
  )
}

export default createFragmentContainer(SchoolCard, {
  school: graphql`
    fragment SchoolCard_school on School {
      legacyId
      name
      numRatings
      avgRating
      avgRatingRounded
      ...SchoolCardHeader_school
      ...SchoolCardLocation_school
    }
  `
})

SchoolCard.propTypes = {
  school: SchoolTypes
}
