import React from 'react'
import styled, { css } from 'styled-components'
import ReactRouterPropTypes from 'react-router-prop-types'
import { applyBreakpointMin } from '@StyledComponents/theme/helpers/breakpoints'
import isSSR from '@utils/isSSR'
import { OutbrainWidget } from 'react-outbrain-widget'

const StyledOutbrainAd = styled.div`
  ${applyBreakpointMin(
    'large',
    css`
      max-width: calc(
        ${props => props.theme.pageWrapper.maxWidth}px - ${props => props.theme.adRail.width}px
      );
    `
  )}
`

export default function OutbrainAd({ history }) {
  if (isSSR()) return null

  const srcUrl = `https://www.ratemyprofessors.com${history?.location?.pathname}`

  return (
    <StyledOutbrainAd>
      <OutbrainWidget dataSrc={srcUrl} dataWidgetId="AR_1" />
    </StyledOutbrainAd>
  )
}

OutbrainAd.propTypes = {
  history: ReactRouterPropTypes.history
}
