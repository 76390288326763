/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useState, useEffect, useRef } from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import PropTypes from 'prop-types'
import { generatePath, useHistory } from 'react-router-dom'
import SEARCH_ITEM_TYPES from '@enums/SEARCHITEMTYPES'
import TRACKING_EVENT from '@enums/TRACKING_EVENT'
import ROUTE from '@enums/ROUTE'
import TeacherTypes from '@types/Teacher.types'
import SchoolTypes from '@types/School.types'
import parseAndSanitize from '@utils/parseAndSanitize'
import SearchContext from '@contexts/SearchContext'
import Apple from '@assets/images/js/Apple'
import GraduationCap from '@assets/images/js/GraduationCap'
import {
  breakpointLargeAndBelow,
  breakpointSmallAndBelow
} from '@StyledComponents/theme/helpers/breakpoints'
import Link, { LINK_TYPE } from './Link'

export const getItemURL = (itemType, item) => {
  if (itemType === SEARCH_ITEM_TYPES.TEACHERS)
    return generatePath(ROUTE.SHOW_RATINGS, { tid: item.legacyId })
  return generatePath(ROUTE.NEW_CAMPUS_RATINGS, { sid: item.legacyId })
}

const getLocation = school => {
  if (school.city && school.state) return `${school.city}, ${school.state}`

  return school.city || school.state
}

const TypeaheadLink = ({ itemType, children, isNewSearch, index, selectedIndex, ...props }) => {
  // isNewSearch, index, and selectedIndex are all used for styling, however
  // they need to be intentionally extracted from props so they don't get passed
  // to the underlying dom element, which would trigger a react error.

  return (
    <Link tabIndex={-1} {...props} type={LINK_TYPE.INTERNAL}>
      {children}
    </Link>
  )
}

export const MenuItemHeader = styled.div`
  color: ${props => props.theme.color.default};
  font-size: ${props => props.theme.fontSize.medium};
  font-weight: ${props => props.theme.fontWeight.bold};
  padding-bottom: 5px;
`

const MenuItemSubHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const MenuItemSubHeaderText = styled.span`
  color: ${({ theme }) => theme.color.gray9};
  max-width: ${props => (props.hideLink ? '100%' : '115px')};
  overflow: hidden;
  text-overflow: ${props => (props.hideLink ? 'none' : 'ellipsis')};
  white-space: nowrap;

  ${breakpointLargeAndBelow(
    css`
      max-width: unset;
    `
  )}
`
const StyledLi = styled.li``

export const NewSearchSubheaderDivder = styled.span`
  color: ${({ theme }) => theme.color.gray9};
  display: ${props => (props.hideLink ? 'none' : null)};
  padding: 0 5px;
`

export const NewSearchSchoolName = styled.span`
  color: ${({ theme }) => theme.color.gray8};
  min-height: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 210px;

  ${breakpointLargeAndBelow(
    css`
      width: unset;
    `
  )}
`

export const NewSearchSchoolLink = styled(Link)`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-decoration: underline !important;
`

const selectedStyles = css`
  ${({ theme }) => {
    return css`
      background: ${theme.color.darkMainBlue};

      ${MenuItemHeader},
      ${NewSearchSchoolName},
      ${MenuItemSubHeaderText},
      ${NewSearchSchoolLink} {
        color: white;
      }
    `
  }}
`

export const StyledLink = styled(TypeaheadLink)`
  display: flex;
  padding: ${({ isNewSearch }) => (isNewSearch ? '13px 26px' : '8px 16px')};
  text-decoration: none;

  ${breakpointSmallAndBelow(css`
    padding: ${({ isNewSearch }) => (isNewSearch ? '13px 16px' : '8px 16px')};

    svg {
      display: none;
    }
  `)}

  ${({ index, selectedIndex }) => {
    if (index === selectedIndex) {
      return selectedStyles
    }

    return css`
      &:hover {
        ${selectedStyles}

        svg > path {
          fill: white;
        }
      }
    `
  }}

  &:hover {
    ${NewSearchSchoolLink},
    ${NewSearchSubheaderDivder} {
      color: ${({ theme }) => theme.color.white};
    }
  }
`

export const StyledMenuItem = styled.div`
  padding-left: ${({ isNewSearch }) => (isNewSearch ? '12px' : 0)};

  text-align: left;

  a {
    text-decoration: none;
  }

  &:hover {
    ${MenuItemHeader} {
      color: ${props => props.theme.color.white};
    }
  }
`

const getSubHeaderText = (item, hideLink) => {
  const departmentOrLocation = item.department || getLocation(item)

  if (!departmentOrLocation) return null

  return (
    <React.Fragment>
      <MenuItemSubHeaderText hideLink={hideLink}>{departmentOrLocation}</MenuItemSubHeaderText>
      <NewSearchSubheaderDivder hideLink={hideLink}>•</NewSearchSubheaderDivder>
    </React.Fragment>
  )
}

const getSubheader = (isNewSearch, item, itemType, hideLink) => {
  if (isNewSearch) {
    return (
      <MenuItemSubHeader>
        {getSubHeaderText(item, hideLink)}

        {itemType === SEARCH_ITEM_TYPES.SCHOOLS && !hideLink ? (
          <NewSearchSchoolLink
            trackingLabel={TRACKING_EVENT.HERO_RESULTS_SCHOOL_LINK}
            to={getItemURL('school', item)}
            type={LINK_TYPE.INTERNAL}
            onClick={event => {
              event.stopPropagation()
            }}
          >
            Go to school page
          </NewSearchSchoolLink>
        ) : (
          <NewSearchSchoolName>{item?.school?.name}</NewSearchSchoolName>
        )}
      </MenuItemSubHeader>
    )
  }
  return (
    <MenuItemSubHeader>
      <MenuItemSubHeaderText>
        {itemType === SEARCH_ITEM_TYPES.TEACHERS ? item.school.name : getLocation(item)}
      </MenuItemSubHeaderText>
    </MenuItemSubHeader>
  )
}

const getIcon = (itemType, color) => {
  const isTeacherItemType = itemType === SEARCH_ITEM_TYPES.TEACHERS

  if (isTeacherItemType) {
    return <Apple fillColor={color} />
  }

  return <GraduationCap fillColor={color} />
}

export default function MenuItem({
  index,
  itemType,
  item,
  selectedIndex,
  isNewSearch,
  hideLink = false
}) {
  const history = useHistory()
  const theme = useContext(ThemeContext)
  const [iconColor, setIconColor] = useState(theme.color.default)
  const { onItemSelected, setIsOpen } = useContext(SearchContext)
  const isTeacherItemType = itemType === SEARCH_ITEM_TYPES.TEACHERS
  const liRef = useRef()

  useEffect(() => {
    if (index === selectedIndex) {
      liRef.current.scrollIntoView(false)
      setIconColor('#FFFFFF')
    } else {
      setIconColor(theme.color.default)
    }
  }, [index, selectedIndex])

  return (
    <StyledLi ref={liRef}>
      <StyledLink
        to={getItemURL(itemType, item)}
        key={item.legacyId.toString()}
        onClick={e => onItemSelected(history, e, itemType, item, setIsOpen)}
        itemType={itemType}
        isNewSearch={isNewSearch}
        selectedIndex={selectedIndex}
        index={index}
        aria-label={`${isTeacherItemType ? 'Teacher' : 'School'} page for ${
          item.firstName ? `${item.firstName} ${item.lastName}` : `${item.name}`
        }`}
      >
        {isNewSearch && getIcon(itemType, iconColor)}
        <StyledMenuItem isNewSearch={isNewSearch}>
          <MenuItemHeader
            selectedIndex={selectedIndex}
            isNewSearch={isNewSearch}
            selected={index === selectedIndex}
          >
            {isTeacherItemType ? parseAndSanitize(`${item.firstName} ${item.lastName}`) : item.name}
          </MenuItemHeader>
          {getSubheader(isNewSearch, item, itemType, hideLink)}
        </StyledMenuItem>
      </StyledLink>
    </StyledLi>
  )
}

MenuItem.propTypes = {
  index: PropTypes.number,
  itemType: PropTypes.oneOf(Object.values(SEARCH_ITEM_TYPES)),
  item: PropTypes.oneOfType([TeacherTypes, SchoolTypes]),
  selectedIndex: PropTypes.number,
  isNewSearch: PropTypes.bool,
  hideLink: PropTypes.bool
}

TypeaheadLink.propTypes = {
  itemType: PropTypes.oneOf(Object.values(SEARCH_ITEM_TYPES)),
  children: PropTypes.node,
  isNewSearch: PropTypes.bool,
  index: PropTypes.number,
  selectedIndex: PropTypes.number
}
