/**
 * @flow
 * @relayHash 420700bec7a47c6b969dd426d00b15f2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type FormController_validation$ref = any;
export type UserInputObject = {|
  email: string,
  password?: ?string,
  departments?: ?$ReadOnlyArray<?DeptInputObject>,
  major?: ?string,
  firstName?: ?string,
  lastName?: ?string,
  schoolId?: ?string,
  graduationYear?: ?number,
  currentStanding?: ?string,
|};
export type DeptInputObject = {|
  id?: ?string,
  name?: ?string,
|};
export type PasswordFormMutationVariables = {|
  user: UserInputObject,
  email: string,
  password: string,
|};
export type PasswordFormMutationResponse = {|
  +userCreate: ?{|
    +user: ?{|
      +id: ?string,
      +Email: ?string,
    |},
    +validation: ?{|
      +$fragmentRefs: FormController_validation$ref
    |},
  |},
  +userLogin: ?{|
    +token: ?string,
    +validation: ?{|
      +$fragmentRefs: FormController_validation$ref
    |},
  |},
|};
export type PasswordFormMutation = {|
  variables: PasswordFormMutationVariables,
  response: PasswordFormMutationResponse,
|};
*/


/*
mutation PasswordFormMutation(
  $user: UserInputObject!
  $email: String!
  $password: String!
) {
  userCreate(user: $user) {
    user {
      id
      Email
    }
    validation {
      ...FormController_validation
      id
    }
  }
  userLogin(email: $email, password: $password) {
    token
    validation {
      ...FormController_validation
      id
    }
  }
}

fragment FormController_validation on ValidationResult {
  id
  success
  errors {
    field
    messages
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "user",
    "type": "UserInputObject!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "email",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "password",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "user",
    "variableName": "user"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "user",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "Email",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "validation",
  "storageKey": null,
  "args": null,
  "concreteType": "ValidationResult",
  "plural": false,
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "FormController_validation",
      "args": null
    }
  ]
},
v5 = [
  {
    "kind": "Variable",
    "name": "email",
    "variableName": "email"
  },
  {
    "kind": "Variable",
    "name": "password",
    "variableName": "password"
  }
],
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "token",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "validation",
  "storageKey": null,
  "args": null,
  "concreteType": "ValidationResult",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "success",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "errors",
      "storageKey": null,
      "args": null,
      "concreteType": "FieldErrorType",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "field",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "messages",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PasswordFormMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "userCreate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UserMutationResponse",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "userLogin",
        "storageKey": null,
        "args": (v5/*: any*/),
        "concreteType": "AuthenticationResponse",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v4/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PasswordFormMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "userCreate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UserMutationResponse",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v7/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "userLogin",
        "storageKey": null,
        "args": (v5/*: any*/),
        "concreteType": "AuthenticationResponse",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "PasswordFormMutation",
    "id": null,
    "text": "mutation PasswordFormMutation(\n  $user: UserInputObject!\n  $email: String!\n  $password: String!\n) {\n  userCreate(user: $user) {\n    user {\n      id\n      Email\n    }\n    validation {\n      ...FormController_validation\n      id\n    }\n  }\n  userLogin(email: $email, password: $password) {\n    token\n    validation {\n      ...FormController_validation\n      id\n    }\n  }\n}\n\nfragment FormController_validation on ValidationResult {\n  id\n  success\n  errors {\n    field\n    messages\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd8f73814500de352084e0b15ee2f9aa2';
module.exports = node;
