import { generatePath, matchPath } from 'react-router'
import ROUTE from '@enums/ROUTE'
import isSSR from '@utils/isSSR'

export const COMPARE_ACTIONS = {
  SELECT: 'SELECT',
  EDIT: 'EDIT',
  RESET: 'RESET',
  COOKIE: 'COOKIE'
}

export const handleUrlChange = ({ action, firstSchool, secondSchool }) => {
  /**
   * Some links will have UTM params on them, if a school is changed we
   * want to remove the existing UTM params so we split the url on '?'
   */
  let url = window.location.href.split('?')[0]
  const firstSchoolExists = window.location.pathname?.split('/')[3]

  if (action === COMPARE_ACTIONS.COOKIE) {
    if (firstSchoolExists) return
    if (!url.endsWith('/')) {
      url += '/'
    }
    url += `${firstSchool.legacyId}`
  }

  if (action === COMPARE_ACTIONS.SELECT) {
    if (!url.endsWith('/')) {
      url += '/'
    }
    url += secondSchool ? `${secondSchool.legacyId}` : `${firstSchool.legacyId}`
  }

  if (action === COMPARE_ACTIONS.EDIT && secondSchool) {
    url = url.replace(`/${secondSchool.legacyId}`, '')
  }

  if (action === COMPARE_ACTIONS.RESET) {
    url = `${window.location.origin}`.concat(`${generatePath(ROUTE.COMPARE_SCHOOLS, {})}`)
  }

  window.history.pushState({}, '', url)
}

export const mapQueryData = ({ school }) => {
  if (!school) return null

  const { legacyId, name, city, state, country, summary, numRatings, avgRatingRounded } = school

  return {
    legacyId: legacyId,
    name: name,
    city: city,
    state: state,
    country: country,
    numRatings: numRatings,
    avgRating: avgRatingRounded,
    categories: [
      { name: 'Internet', score: summary?.internetSpeed },
      { name: 'Safety', score: summary?.schoolSafety },
      { name: 'Facilities', score: summary?.campusCondition },
      { name: 'Opportunities', score: summary?.careerOpportunities },
      { name: 'Location', score: summary?.campusLocation },
      { name: 'Clubs', score: summary?.clubAndEventActivities },
      { name: 'Social', score: summary?.socialActivities },
      { name: 'Food', score: summary?.foodQuality }
    ]
  }
}

export const getMetaTitle = ({ primarySchool, secondSchool }) => {
  if (!primarySchool) {
    return `Compare Schools`
  }
  return `Compare Schools | ${primarySchool?.name} ${
    secondSchool ? `vs. ${secondSchool.name}` : ''
  }`
}

/* eslint-disable import/prefer-default-export */
export const getVariables = (location, savedSchool) => {
  const match = matchPath(location.pathname, { path: ROUTE.COMPARE_SCHOOLS })

  const primaryIdExists = match?.params?.primaryId
  const secondaryIdExists = match?.params?.secondaryId

  if (!primaryIdExists && savedSchool) {
    if (!isSSR())
      handleUrlChange({
        action: COMPARE_ACTIONS.COOKIE,
        firstSchool: savedSchool
      })

    return {
      primaryId: btoa(`School-${savedSchool.legacyId}`),
      secondaryId: '',
      skipPrimary: false,
      skipSecondary: true
    }
  }

  if (!primaryIdExists && !savedSchool) {
    return {
      primaryId: '',
      secondaryId: '',
      skipPrimary: true,
      skipSecondary: true
    }
  }

  if (primaryIdExists && !secondaryIdExists) {
    return {
      primaryId: btoa(`School-${match.params.primaryId}`),
      secondaryId: '',
      skipPrimary: false,
      skipSecondary: true
    }
  }

  return {
    primaryId: btoa(`School-${match.params.primaryId}`),
    secondaryId: btoa(`School-${match.params.secondaryId}`),
    skipPrimary: false,
    skipSecondary: false
  }
}
