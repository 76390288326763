import React from 'react'
import styled, { css } from 'styled-components'
import { createFragmentContainer, graphql } from 'react-relay'
import PropTypes from 'prop-types'
import TeacherTypes from '@types/Teacher.types'
import SchoolTypes from '@types/School.types'
import TeacherBookmark, { StyledTeacherBookmark } from '@StyledComponents/TeacherBookmark'
import TRACKING_EVENT from '@enums/TRACKING_EVENT'
import parseAndSanitize from '@utils/parseAndSanitize'
import { poppinsBoldest } from '@StyledComponents/theme/typesets'
import {
  breakpointSmediumAndBelow,
  getPageContainerMaxWidth
} from '@StyledComponents/theme/helpers/breakpoints'
import TeacherTitles from './TeacherTitles'
import { HeaderRateButton } from './HeaderRateButton'

const StyledInfoContainer = styled.div`
  min-width: 100%;
  ${({ theme }) => getPageContainerMaxWidth(theme)}
  ${breakpointSmediumAndBelow(css`
    width: 100%;
  `)}
`

export const StyledTitleName = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-weight: bold;
  line-height: 36px;
  padding-bottom: 2px;
  text-align: left;
  ${poppinsBoldest};

  ${breakpointSmediumAndBelow(css`
    justify-content: space-between;
  `)}

  ${StyledTeacherBookmark} {
    margin-top: 7px;

    ${breakpointSmediumAndBelow(css`
      margin-right: 10px;
      margin-top: 6px;
    `)}

    img {
      width: 30px;
    }

    &:hover {
      cursor: pointer;
    }
  }
`

export const StyledCityState = styled.span`
  color: ${({ theme }) => theme.color.gray11};
  display: flex;
  font-size: 16px;
  font-weight: 500;
  justify-content: left;
  line-height: 20px;
  margin-bottom: 4px;
  max-width: 100%;
`

export const RateProfessorTitle = styled.div`
  display: inline;
  font-size: 24px;
  font-weight: 400 !important;
  min-width: 92px;
  ${poppinsBoldest};
`

export const StyledSubTitlesContainer = styled.div`
  font-size: 16px;
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: 20px;
  max-width: 100%;
  text-align: left;

  .span {
    color: ${props => props.theme.color.gray11};
  }
`

const NameWrapper = styled.span`
  font-size: 32px;
  text-decoration: none;
  white-space: normal;
  ${poppinsBoldest};

  ${breakpointSmediumAndBelow(css`
    font-size: 28px;
    line-height: 28px;
  `)}
`

const StyledSmallHeaderWrapper = styled.div`
  align-items: left;
  display: flex;
  flex-direction: column;
`

const StyledInfo = styled.div`
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  max-width: 732px;
`

export function HeaderDescription({ teacher, large, school, showButtons }) {
  const mainTitle = teacher ? (
    <React.Fragment>
      <NameWrapper>
        {parseAndSanitize(teacher.firstName)} {parseAndSanitize(teacher.lastName)}
      </NameWrapper>
    </React.Fragment>
  ) : (
    <NameWrapper>{parseAndSanitize(school?.name)}</NameWrapper>
  )

  const location =
    school && school.city && school.state
      ? `${school.city}${school.city && school.state && ', '}${school.state}`
      : null

  const largeHeader = (
    <StyledTitleName>
      {mainTitle}
      <div>
        <TeacherBookmark
          teacher={teacher}
          saveEvent={TRACKING_EVENT.PROFILE_PAGE_SAVED_PROF_ADD}
          unsaveEvent={TRACKING_EVENT.PROFILE_PAGE_SAVED_PROF_REMOVE}
        />
      </div>
    </StyledTitleName>
  )

  const smallHeader = (
    <StyledTitleName>
      <StyledSmallHeaderWrapper>
        {mainTitle}
        <RateProfessorTitle>Add Rating </RateProfessorTitle>
      </StyledSmallHeaderWrapper>
    </StyledTitleName>
  )

  return (
    <StyledInfoContainer>
      <StyledInfo>
        <StyledCityState>{location}</StyledCityState>
        {large ? largeHeader : smallHeader}
        <StyledSubTitlesContainer>
          {teacher && <TeacherTitles teacher={teacher} large={large} />}
        </StyledSubTitlesContainer>
        {showButtons && <HeaderRateButton teacher={teacher} school={school} large={large} />}
      </StyledInfo>
    </StyledInfoContainer>
  )
}

HeaderDescription.propTypes = {
  teacher: TeacherTypes,
  large: PropTypes.bool,
  school: SchoolTypes,
  showButtons: PropTypes.bool
}

export default createFragmentContainer(HeaderDescription, {
  teacher: graphql`
    fragment HeaderDescription_teacher on Teacher {
      id
      legacyId
      firstName
      lastName
      department
      school {
        legacyId
        name
        city
        state
      }
      ...TeacherTitles_teacher
      ...TeacherBookmark_teacher
      ...RateTeacherLink_teacher
      ...CompareProfessorLink_teacher
    }
  `,
  school: graphql`
    fragment HeaderDescription_school on School {
      name
      city
      state
      legacyId
      ...RateSchoolLink_school
      ...CompareSchoolLink_school
    }
  `
})
