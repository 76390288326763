import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import useGlobalContext from '@hooks/useGlobalContext'
import VOTEDIRECTION from '@enums/VOTEDIRECTION'
import ThumbsUp from '@assets/images/thumbs-up-black.svg'
import ThumbsDown from '@assets/images/thumbs-down-black.svg'
import ThumbUpGreen from '@assets/images/thumbs-up-green.svg'
import ThumbDownRed from '@assets/images/thumbs-down-red.svg'
import MODALS from '@enums/MODALS'
import TOOLTIPS from '@enums/TOOLTIPS'
import { useDynamicTooltip } from '@components/Tooltip/Tooltip'
import isMaintenanceMode from '@utils/isMaintenanceMode'
import theme from './theme'

export const StyledVoteThumb = styled.img`
  margin-right: 5px;
`

const StyledVoteContainer = styled.div`
  align-items: center;
  cursor: ${props =>
    props.canVote || props.shouldPromptSignup || props.shouldPromptConfirmEmail
      ? 'pointer'
      : 'default'};
  display: flex;
`

const HelpfulText = styled.div`
  color: ${theme.color.black};
  margin-right: 5px;
`

// thumbSVGMap determines what the thumb svg will be
const thumbSVGMap = {
  // The voteDirection or 'type of thumb'. In this case, a thumbs up
  [VOTEDIRECTION.UPVOTE]: {
    // The supplied vote.
    [VOTEDIRECTION.UPVOTE]: {
      // true / false indicates the hover state
      true: ThumbUpGreen,
      false: ThumbUpGreen
    },
    [VOTEDIRECTION.DOWNVOTE]: {
      true: ThumbsUp,
      false: ThumbsUp
    },
    // Without a vote
    [null]: {
      true: ThumbUpGreen,
      false: ThumbsUp
    }
  },
  [VOTEDIRECTION.DOWNVOTE]: {
    [VOTEDIRECTION.DOWNVOTE]: {
      true: ThumbDownRed,
      false: ThumbDownRed
    },
    [VOTEDIRECTION.UPVOTE]: {
      true: ThumbsDown,
      false: ThumbsDown
    },
    [null]: {
      true: ThumbDownRed,
      false: ThumbsDown
    }
  }
}

export default function VoteThumb({
  voteDirection,
  addVote,
  vote,
  className,
  locked,
  shouldPromptSignup,
  shouldPromptConfirmEmail,
  tooltip,
  id,
  setDidVote
}) {
  const maintenanceMode = isMaintenanceMode()

  // State to change thumb on hover
  const [hovering, setHovering] = useState(false)

  useDynamicTooltip(tooltip)

  const shouldPrompt = shouldPromptSignup || shouldPromptConfirmEmail
  const canVote =
    !shouldPrompt && !locked && (vote === voteDirection || vote === null) && !maintenanceMode
  if (!voteDirection || !addVote) return null
  const { setActiveModal } = useGlobalContext()

  return (
    <StyledVoteContainer
      canVote={canVote}
      onClick={() => {
        if (canVote) {
          addVote(voteDirection)
          setDidVote(true)
        } else if (maintenanceMode) {
          setActiveModal(MODALS.MAINTENANCE_ANNOUNCEMENT)
        } else if (shouldPromptSignup) {
          setActiveModal(MODALS.SIGN_UP_PROMPT)
        } else if (shouldPromptConfirmEmail) {
          setActiveModal(MODALS.CONFIRM_EMAIL_PROMPT)
        }
      }}
      onMouseEnter={() => {
        setHovering(canVote || shouldPromptSignup)
      }}
      onMouseLeave={() => {
        setHovering(false)
        setDidVote(false)
      }}
    >
      <HelpfulText>{voteDirection === VOTEDIRECTION.UPVOTE ? 'Helpful' : null}</HelpfulText>
      <StyledVoteThumb
        canVote={canVote}
        src={thumbSVGMap[voteDirection][vote][hovering]}
        className={className}
        data-tooltip
        data-tip={tooltip}
        data-for={TOOLTIPS.GLOBAL_TOOLTIP}
        key={`votethumb-${voteDirection}-${id}`}
        alt={voteDirection === VOTEDIRECTION.UPVOTE ? 'Thumbs up' : 'Thumbs down'}
      />
    </StyledVoteContainer>
  )
}

VoteThumb.propTypes = {
  voteDirection: PropTypes.oneOf(Object.values(VOTEDIRECTION)),
  vote: PropTypes.string,
  className: PropTypes.string,
  addVote: PropTypes.func,
  locked: PropTypes.bool,
  shouldPromptSignup: PropTypes.bool,
  shouldPromptConfirmEmail: PropTypes.bool,
  tooltip: PropTypes.string,
  id: PropTypes.string,
  setDidVote: PropTypes.func
}
