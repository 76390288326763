import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { generatePath, withRouter, useHistory } from 'react-router-dom'
import ROUTE from '@enums/ROUTE'
import TeacherTypes from '@types/Teacher.types'
import RatingTypes from '@types/Rating.types'
import PageWrapper from '@components/PageWrapper'
import { TOAST_EVENTS } from '@utils/toast'
import Form from '@components/Form/Form'
import FormCard from '@components/Form/FormCard'
import {
  getPageContentMaxWidth,
  breakpointLargePlusAndBelow,
  breakpointTinyAndBelow
} from '@StyledComponents/theme/helpers/breakpoints'
import AdRail from '@components/Ads/AdRail'
import SEOMetaInfo from '@components/SEOMetaInfo'
import FlagComment from './FlagComment'
import { flagTeacherRatingMutation } from './FlagTeacherRating.utils'
import FlagTeacherSubmit from './FlagTeacherSubmit'

export const StyledFlagTeacherRatingPage = styled(PageWrapper)`
  ${breakpointLargePlusAndBelow(css`
    padding: 0 24px;
  `)}
  ${breakpointTinyAndBelow(css`
    padding: 0;
  `)}
`

const FlagTeacherHeading = styled.div`
  font-size: ${({ theme }) => theme.fontSize.teacherNotes};
  text-align: left;
  span {
    font-weight: bold;
  }
`

export const StyledFlagTeacherRatingForm = styled(Form)`
  ${({ theme }) => getPageContentMaxWidth(theme)};
`

export function FlagTeacherRating({ teacher, rating, loading }) {
  if (loading) {
    return <PageWrapper>Loading...</PageWrapper>
  }
  const history = useHistory()
  const teacherName = `${teacher?.firstName} ${teacher?.lastName}`
  const professorPath = generatePath(ROUTE.SHOW_RATINGS, { tid: teacher.legacyId })

  return (
    <StyledFlagTeacherRatingPage>
      <SEOMetaInfo title="Report rating for professor | Rate My Professors" />
      <FlagTeacherHeading>
        Report a Rating for
        <span>{` ${teacherName}`} </span>
      </FlagTeacherHeading>
      <StyledFlagTeacherRatingForm
        getVariables={model => {
          return {
            ratingID: rating.id,
            flagComment: model.flagComment
          }
        }}
        mutation={flagTeacherRatingMutation}
        onSuccess={() => {
          history.push({
            pathname: professorPath,
            state: { toast: TOAST_EVENTS.FLAG_RATING_SUCCESS }
          })
        }}
      >
        <FormCard fieldName="flagComment">
          <FlagComment rating={rating} />
        </FormCard>
        <FlagTeacherSubmit teacherID={teacher?.legacyId} />
      </StyledFlagTeacherRatingForm>
      <AdRail />
    </StyledFlagTeacherRatingPage>
  )
}

FlagTeacherRating.propTypes = {
  teacher: TeacherTypes,
  rating: RatingTypes,
  loading: PropTypes.bool
}

export default withRouter(FlagTeacherRating)
