import React from 'react'
import styled, { css } from 'styled-components'
import { generatePath } from 'react-router-dom'
import SchoolTypes from '@types/School.types'
import PropTypes from 'prop-types'
import ROUTE from '@enums/ROUTE'
import { breakpointSmediumAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import Link from '@components/Link'

export const StyledTitle = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  white-space: nowrap;

  ${breakpointSmediumAndBelow(css`
    flex-direction: ${props => (props.large ? 'column' : 'row')};
    padding-bottom: ${props => (props.large ? '8px' : '0')};
  `)}
`

const LinkContainer = styled.div`
  margin-left: 0px;
  margin-top: 4px;
`

const linkStyles = css`
  color: ${({ theme }) => theme.color.default};
  font-weight: bold;
  ${breakpointSmediumAndBelow(css`
    font-size: 12px;
  `)}
`
const StyledProfLink = styled(Link)`
  ${linkStyles}
`

export const SchoolTitles = ({ school, large }) => {
  if (!school) {
    return null
  }

  const path = generatePath(ROUTE.TEACHER_SEARCH, { sid: school.legacyId })

  return (
    <StyledTitle large={large}>
      {large && (
        <LinkContainer>
          <StyledProfLink to={`${path}?q=*`}>View all Professors</StyledProfLink>
        </LinkContainer>
      )}
    </StyledTitle>
  )
}

export default SchoolTitles

SchoolTitles.propTypes = {
  school: SchoolTypes,
  large: PropTypes.bool
}
