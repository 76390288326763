/**
 * @flow
 * @relayHash 5fb9cc32461b898a5a4d13a07045b1b5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GlobalContextProviderQueryVariables = {||};
export type GlobalContextProviderQueryResponse = {|
  +user: ?{|
    +firstName: ?string,
    +id: ?string,
    +legacyId: ?number,
    +Email: ?string,
    +Type: ?string,
    +graduationYear: ?number,
    +emailConfirmed: ?boolean,
    +major: ?string,
    +school: ?{|
      +id: ?string,
      +legacyId: ?number,
      +name: ?string,
    |},
  |}
|};
export type GlobalContextProviderQuery = {|
  variables: GlobalContextProviderQueryVariables,
  response: GlobalContextProviderQueryResponse,
|};
*/


/*
query GlobalContextProviderQuery {
  user {
    firstName
    id
    legacyId
    Email
    Type
    graduationYear
    emailConfirmed
    major
    school {
      id
      legacyId
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "legacyId",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "user",
    "storageKey": null,
    "args": null,
    "concreteType": "User",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "firstName",
        "args": null,
        "storageKey": null
      },
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "Email",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "Type",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "graduationYear",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "emailConfirmed",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "major",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "school",
        "storageKey": null,
        "args": null,
        "concreteType": "School",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "GlobalContextProviderQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "GlobalContextProviderQuery",
    "argumentDefinitions": [],
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "GlobalContextProviderQuery",
    "id": null,
    "text": "query GlobalContextProviderQuery {\n  user {\n    firstName\n    id\n    legacyId\n    Email\n    Type\n    graduationYear\n    emailConfirmed\n    major\n    school {\n      id\n      legacyId\n      name\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '27d476f72c2af2d55241be408733051a';
module.exports = node;
