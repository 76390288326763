import React from 'react'
import styled, { css } from 'styled-components'
import { breakpointXMediumAndBelow } from '@components/StyledComponents/theme/helpers/breakpoints'
import AdLeaderboard from '@components/Ads/AdLeaderboard'

const StyledHomepageMidAds = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0;
  width: 100%;

  ${breakpointXMediumAndBelow(css`
    align-items: center;
    flex-direction: column;
  `)}
`

export default function HomepageMidAds() {
  return (
    <StyledHomepageMidAds>
      <AdLeaderboard code="ad_lb_home" schoolHeader center />
    </StyledHomepageMidAds>
  )
}
