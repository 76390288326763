import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import GradeSquare from '@components/StyledComponents/GradeSquare'
import Button from '@components/Button'
import { poppinsBold, poppinsBoldest } from '@components/StyledComponents/theme/typesets'
import CompareSchoolTypes from '@types/CompareSchool.types'
import Link, { LINK_TYPE } from '@components/Link'
import ROUTE from '@enums/ROUTE'
import roundToOneDecimal from '@utils/roundToOneDecimal'
import { generatePath } from 'react-router-dom'

const SchoolRatingsDetails = styled.div`
  font-size: 12px;
  margin-top: 4px;
  ${props => props.overall && poppinsBold};
`

const SchoolName = styled.div`
  font-size: ${props => (props.search ? `12px` : '20px')};
  line-height: 20px;
  ${props => (props.noMargin ? null : `margin-top: 24px`)};
  ${props => (props.search ? null : poppinsBoldest)};

  ${props =>
    props.underline &&
    css`
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    `}
`

const SchoolEditButton = styled(Button)`
  background-color: ${({ theme }) => theme.color.gray5};
  border: inherit;
  border-radius: 33px;
  cursor: pointer;
  font-size: 9px;
  margin: 16px auto;
  padding: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.color.gray4};
  }
`

const SchoolGradeSquareContainer = styled.div`
  display: inline-block;
  ${props => (props.includeMargin ? `margin-top: 16px;` : null)}
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const SchoolLink = ({ children, school }) => {
  const schoolPath = generatePath(ROUTE.NEW_CAMPUS_RATINGS, { sid: school.legacyId ?? 0 })
  return (
    <StyledLink type={LINK_TYPE.INTERNAL} to={schoolPath}>
      {children}
    </StyledLink>
  )
}

SchoolLink.propTypes = {
  children: PropTypes.node,
  school: CompareSchoolTypes
}

const CompareSchoolsHeaderBox = ({ primary, school, handleEditSchool }) => {
  if (!school) {
    return (
      <React.Fragment>
        <SchoolGradeSquareContainer>
          <GradeSquare large />
        </SchoolGradeSquareContainer>
        <SchoolRatingsDetails overall>OVERALL</SchoolRatingsDetails>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <SchoolGradeSquareContainer includeMargin>
        <GradeSquare large grade={roundToOneDecimal(school.avgRating)} />
      </SchoolGradeSquareContainer>
      <SchoolRatingsDetails overall>OVERALL</SchoolRatingsDetails>
      <SchoolRatingsDetails>{school.numRatings} Ratings</SchoolRatingsDetails>
      <SchoolLink school={school}>
        <SchoolName underline>{school.name}</SchoolName>
      </SchoolLink>
      {!primary && school && (
        <SchoolEditButton onClick={() => handleEditSchool({ removedSchool: school })}>
          Change School
        </SchoolEditButton>
      )}
    </React.Fragment>
  )
}

CompareSchoolsHeaderBox.propTypes = {
  primary: PropTypes.bool,
  school: CompareSchoolTypes,
  handleEditSchool: PropTypes.func
}

export default CompareSchoolsHeaderBox
