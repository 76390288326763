import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { createFragmentContainer, graphql } from 'react-relay'
import { generatePath, Redirect, matchPath, useLocation } from 'react-router'
import styled from 'styled-components'
import ROUTE from '@enums/ROUTE'
import PageWrapper from '@components/PageWrapper'
import AdRail from '@components/Ads/AdRail'
import AdLeaderboard from '@components/Ads/AdLeaderboard'
import theme from '@components/StyledComponents/theme'
import SEARCH_CATEGORY from '@enums/SEARCH_CATEGORY'
import { buttonIconConfig } from '@components/Header/TeacherSchoolToggleButton'
import SchoolTypes from '@types/School.types'
import { poppinsBoldest } from '@components/StyledComponents/theme/typesets'
import SEOMetaInfo from '@components/SEOMetaInfo'
import toast, { TOAST_EVENTS } from '@utils/toast'
import ShareComparisonToast from '@components/Toast/ShareComparisonToast'
import { getCountryName } from '@pages/SchoolRatingsPage/SchoolRatings.utils'
import Tooltip from '@components/Tooltip/Tooltip'
import CompareSchoolsTable, { ResetButton, ShareComparisonButton } from './CompareSchoolsTable'
import {
  COMPARE_ACTIONS,
  getMetaTitle,
  handleUrlChange,
  mapQueryData
} from './CompareSchools.utils'

export const AD_TOP_COMPARE_PAGE = 120

const CompareSchoolsTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 864px;
`

const CompareSchoolsTitle = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 32px;
  hyphens: auto;
  justify-content: space-between;
  overflow-wrap: break-word;
  padding-bottom: 10px;
  text-align: left;
  word-break: break-word;
  ${poppinsBoldest};
`

const CompareSchoolsButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

export const IconWrapper = styled.span`
  align-items: center;
  margin-right: 12px;
`

export function CompareSchools({ primarySchool, secondarySchool, loading }) {
  const location = useLocation()

  if (loading) {
    return <div>Loading...</div>
  }

  if (matchPath(location.pathname, { path: ROUTE.COMPARE_BLANK, exact: true })) {
    return <Redirect to={generatePath(ROUTE.COMPARE_SCHOOLS, {})} />
  }

  const [firstSchool, setFirstSchool] = useState(mapQueryData({ school: primarySchool }))
  const [secondSchool, setSecondSchool] = useState(mapQueryData({ school: secondarySchool }))

  const [adTargeting, setAdTargeting] = useState()

  useEffect(() => {
    if (!firstSchool) return
    setAdTargeting({
      school_name: firstSchool.name,
      school_city: firstSchool.city,
      school_state: firstSchool.state,
      school_country: getCountryName(firstSchool.country)
    })
  }, [])

  const config = buttonIconConfig[SEARCH_CATEGORY.SCHOOLS]

  const handleEditSchool = ({ removedSchool }) => {
    setSecondSchool(null)
    handleUrlChange({
      action: COMPARE_ACTIONS.EDIT,
      secondSchool: removedSchool
    })
  }

  const handleSchoolSelection = ({ primary, selectedSchool }) => {
    if (primary) {
      setFirstSchool(mapQueryData({ school: selectedSchool }))
    } else {
      setSecondSchool(mapQueryData({ school: selectedSchool }))
    }
    handleUrlChange({
      action: COMPARE_ACTIONS.SELECT,
      firstSchool: primary ? selectedSchool : null,
      secondSchool: !primary ? selectedSchool : null
    })
  }

  const handleOnShareClicked = async () => {
    const currentUrlWithUTMs = `${window.location.href}?utm_source=share&utm_medium=web&utm_campaign=compare_school`
    await navigator?.clipboard?.writeText(currentUrlWithUTMs)
    toast(ShareComparisonToast, TOAST_EVENTS.SHARE_COMPARISON)
  }

  const handleReset = async () => {
    setFirstSchool(null)
    setSecondSchool(null)
    handleUrlChange({
      action: COMPARE_ACTIONS.RESET
    })
  }

  useEffect(() => {
    if (primarySchool) {
      setFirstSchool(mapQueryData({ school: primarySchool }))
    }
    if (secondarySchool) {
      setSecondSchool(mapQueryData({ school: secondarySchool }))
    }
  }, [primarySchool, secondarySchool])

  return (
    <PageWrapper>
      <Tooltip id="compare-schools-tooltip" />
      <SEOMetaInfo
        title={getMetaTitle({ primarySchool: primarySchool, secondSchool: secondSchool })}
        description="See how these schools compare, and compare others"
      />
      <AdLeaderboard code="ad_lb_top_ratings" />
      <CompareSchoolsTitleContainer>
        <CompareSchoolsTitle>
          <IconWrapper>{config.icon(theme.color.black, '24', '32')}</IconWrapper>Compare Schools
        </CompareSchoolsTitle>
        <CompareSchoolsButtonWrapper>
          <ResetButton
            id="reset-button"
            data-testid="reset-button"
            height="20px"
            width="15px"
            header
            disabled={!firstSchool && !secondSchool}
            onClick={handleReset}
          >
            Reset
          </ResetButton>
          <ShareComparisonButton
            id="share-comparison-button"
            header
            disabled={!secondSchool}
            secondarySchool={secondSchool}
            onClick={handleOnShareClicked}
          >
            Share Comparison
          </ShareComparisonButton>
        </CompareSchoolsButtonWrapper>
      </CompareSchoolsTitleContainer>

      <CompareSchoolsTable
        primarySchool={firstSchool}
        secondarySchool={secondSchool}
        handleEditSchool={handleEditSchool}
        handleSchoolSelection={handleSchoolSelection}
        handleOnShareClicked={handleOnShareClicked}
        handleReset={handleReset}
      />
      <AdRail adTop={AD_TOP_COMPARE_PAGE} adTargetingSchools={adTargeting} />
    </PageWrapper>
  )
}

CompareSchools.propTypes = {
  primarySchool: SchoolTypes,
  secondarySchool: SchoolTypes,
  loading: PropTypes.bool
}

export default createFragmentContainer(CompareSchools, {
  primarySchool: graphql`
    fragment CompareSchools_primarySchool on School {
      name
      city
      state
      country
      numRatings
      avgRatingRounded
      legacyId
      summary {
        campusCondition
        campusLocation
        careerOpportunities
        clubAndEventActivities
        foodQuality
        internetSpeed
        libraryCondition
        schoolReputation
        schoolSafety
        schoolSatisfaction
        socialActivities
      }
    }
  `,
  secondarySchool: graphql`
    fragment CompareSchools_secondarySchool on School {
      name
      city
      state
      country
      numRatings
      avgRatingRounded
      legacyId
      summary {
        campusCondition
        campusLocation
        careerOpportunities
        clubAndEventActivities
        foodQuality
        internetSpeed
        libraryCondition
        schoolReputation
        schoolSafety
        schoolSatisfaction
        socialActivities
      }
    }
  `
})
