import React from 'react'
import PropTypes from 'prop-types'
import { generatePath } from 'react-router-dom'
import { createFragmentContainer, graphql } from 'react-relay'
import TeacherTypes from '@types/Teacher.types'
import ROUTE from '@enums/ROUTE'
import LOCK_STATUS from '@enums/LOCK_STATUS'
import useUserInfo from '@hooks/useUserInfo'
import useGlobalContext from '@hooks/useGlobalContext'
import TRACKING_EVENT from '@enums/TRACKING_EVENT'
import MODALS from '@enums/MODALS'
import isMaintenanceMode from '@utils/isMaintenanceMode'
import Link from './Link'

export function RateTeacherLink({
  teacher,
  trackingLabel,
  blockedTrackingLabel = TRACKING_EVENT.REGWALL_OPEN_PROF_SL,
  className,
  children,
  id
}) {
  const maintenanceMode = isMaintenanceMode()

  if (teacher.lockStatus === LOCK_STATUS.HARD) return null

  const userInfo = useUserInfo()
  const { setActiveModal } = useGlobalContext()

  const isLoggedIn = userInfo && userInfo.Email
  const preventRating = teacher.lockStatus === LOCK_STATUS.SOFT && !isLoggedIn

  const onClick = e => {
    if (preventRating) {
      e.preventDefault()
      setActiveModal(MODALS.SIGN_UP_PROMPT)
    }
    if (maintenanceMode) {
      e.preventDefault()
      setActiveModal(MODALS.MAINTENANCE_ANNOUNCEMENT)
    }
  }

  return (
    <Link
      className={className}
      to={generatePath(ROUTE.NEW_ADD_RATING_TEACHER, { tid: teacher.legacyId })}
      onClick={onClick}
      trackingLabel={preventRating ? blockedTrackingLabel : trackingLabel}
      id={id}
    >
      {children}
    </Link>
  )
}

export default createFragmentContainer(RateTeacherLink, {
  teacher: graphql`
    fragment RateTeacherLink_teacher on Teacher {
      legacyId
      numRatings
      lockStatus
    }
  `
})

RateTeacherLink.propTypes = {
  teacher: TeacherTypes.isRequired,
  trackingLabel: PropTypes.string.isRequired,
  blockedTrackingLabel: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string
}
