/**
 * @flow
 * @relayHash da76ff59c727d67612dfafedf1bc88de
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CompareProfessorsColumn_teacher$ref = any;
export type TeacherSearchQuery = {|
  text?: ?string,
  schoolID?: ?string,
  departmentID?: ?string,
  sort?: ?string,
  fallback?: ?boolean,
|};
export type NewSearchTeachersQueryVariables = {|
  query: TeacherSearchQuery,
  count?: ?number,
  includeCompare: boolean,
|};
export type NewSearchTeachersQueryResponse = {|
  +newSearch: ?{|
    +teachers: ?{|
      +didFallback: ?boolean,
      +edges: ?$ReadOnlyArray<?{|
        +cursor: string,
        +node: ?{|
          +id: ?string,
          +legacyId: ?number,
          +firstName: ?string,
          +lastName: ?string,
          +department: ?string,
          +departmentId: ?string,
          +school: ?{|
            +legacyId: ?number,
            +name: ?string,
          |},
          +$fragmentRefs: CompareProfessorsColumn_teacher$ref,
        |},
      |}>,
    |}
  |}
|};
export type NewSearchTeachersQuery = {|
  variables: NewSearchTeachersQueryVariables,
  response: NewSearchTeachersQueryResponse,
|};
*/


/*
query NewSearchTeachersQuery(
  $query: TeacherSearchQuery!
  $count: Int
  $includeCompare: Boolean!
) {
  newSearch {
    teachers(query: $query, first: $count) {
      didFallback
      edges {
        cursor
        node {
          id
          legacyId
          firstName
          lastName
          department
          departmentId
          school {
            legacyId
            name
            id
          }
          ...CompareProfessorsColumn_teacher @include(if: $includeCompare)
        }
      }
    }
  }
}

fragment CompareProfessorsColumn_teacher on Teacher {
  id
  legacyId
  firstName
  lastName
  school {
    legacyId
    name
    id
  }
  department
  departmentId
  avgRating
  numRatings
  wouldTakeAgainPercentRounded
  mandatoryAttendance {
    yes
    no
    neither
    total
  }
  takenForCredit {
    yes
    no
    neither
    total
  }
  ...NoRatingsArea_teacher
  ...RatingDistributionWrapper_teacher
}

fragment NoRatingsArea_teacher on Teacher {
  lastName
  ...RateTeacherLink_teacher
}

fragment RatingDistributionWrapper_teacher on Teacher {
  ...NoRatingsArea_teacher
  ratingsDistribution {
    total
    ...RatingDistributionChart_ratingsDistribution
  }
}

fragment RatingDistributionChart_ratingsDistribution on ratingsDistribution {
  r1
  r2
  r3
  r4
  r5
}

fragment RateTeacherLink_teacher on Teacher {
  legacyId
  numRatings
  lockStatus
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "query",
    "type": "TeacherSearchQuery!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeCompare",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "didFallback",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "legacyId",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "department",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "departmentId",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "total",
  "args": null,
  "storageKey": null
},
v12 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "yes",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "no",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "neither",
    "args": null,
    "storageKey": null
  },
  (v11/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "NewSearchTeachersQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "newSearch",
        "storageKey": null,
        "args": null,
        "concreteType": "newSearch",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "teachers",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "TeacherSearchConnectionConnection",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TeacherSearchConnectionEdge",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Teacher",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "school",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "School",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v10/*: any*/)
                        ]
                      },
                      {
                        "kind": "Condition",
                        "passingValue": true,
                        "condition": "includeCompare",
                        "selections": [
                          {
                            "kind": "FragmentSpread",
                            "name": "CompareProfessorsColumn_teacher",
                            "args": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "NewSearchTeachersQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "newSearch",
        "storageKey": null,
        "args": null,
        "concreteType": "newSearch",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "teachers",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "TeacherSearchConnectionConnection",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TeacherSearchConnectionEdge",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Teacher",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "school",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "School",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v10/*: any*/),
                          (v4/*: any*/)
                        ]
                      },
                      {
                        "kind": "Condition",
                        "passingValue": true,
                        "condition": "includeCompare",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "avgRating",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "numRatings",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "wouldTakeAgainPercentRounded",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "mandatoryAttendance",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "mandatoryAttendance",
                            "plural": false,
                            "selections": (v12/*: any*/)
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "takenForCredit",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "takenForCredit",
                            "plural": false,
                            "selections": (v12/*: any*/)
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "lockStatus",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "ratingsDistribution",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ratingsDistribution",
                            "plural": false,
                            "selections": [
                              (v11/*: any*/),
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "r1",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "r2",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "r3",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "r4",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "r5",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "NewSearchTeachersQuery",
    "id": null,
    "text": "query NewSearchTeachersQuery(\n  $query: TeacherSearchQuery!\n  $count: Int\n  $includeCompare: Boolean!\n) {\n  newSearch {\n    teachers(query: $query, first: $count) {\n      didFallback\n      edges {\n        cursor\n        node {\n          id\n          legacyId\n          firstName\n          lastName\n          department\n          departmentId\n          school {\n            legacyId\n            name\n            id\n          }\n          ...CompareProfessorsColumn_teacher @include(if: $includeCompare)\n        }\n      }\n    }\n  }\n}\n\nfragment CompareProfessorsColumn_teacher on Teacher {\n  id\n  legacyId\n  firstName\n  lastName\n  school {\n    legacyId\n    name\n    id\n  }\n  department\n  departmentId\n  avgRating\n  numRatings\n  wouldTakeAgainPercentRounded\n  mandatoryAttendance {\n    yes\n    no\n    neither\n    total\n  }\n  takenForCredit {\n    yes\n    no\n    neither\n    total\n  }\n  ...NoRatingsArea_teacher\n  ...RatingDistributionWrapper_teacher\n}\n\nfragment NoRatingsArea_teacher on Teacher {\n  lastName\n  ...RateTeacherLink_teacher\n}\n\nfragment RatingDistributionWrapper_teacher on Teacher {\n  ...NoRatingsArea_teacher\n  ratingsDistribution {\n    total\n    ...RatingDistributionChart_ratingsDistribution\n  }\n}\n\nfragment RatingDistributionChart_ratingsDistribution on ratingsDistribution {\n  r1\n  r2\n  r3\n  r4\n  r5\n}\n\nfragment RateTeacherLink_teacher on Teacher {\n  legacyId\n  numRatings\n  lockStatus\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '98217d469c917d6998cf2eac170d954d';
module.exports = node;
