/**
 * @flow
 * @relayHash f973086c0709bf4d1ea6a0ff4142d49e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SchoolSearchQuery = {|
  text?: ?string
|};
export type NewSearchSchoolsQueryVariables = {|
  query?: ?SchoolSearchQuery,
  includeCompare: boolean,
|};
export type NewSearchSchoolsQueryResponse = {|
  +newSearch: ?{|
    +schools: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +cursor: string,
        +node: ?{|
          +id: ?string,
          +legacyId: ?number,
          +name: ?string,
          +city: ?string,
          +state: ?string,
          +departments: ?$ReadOnlyArray<?{|
            +id: ?string,
            +name: ?string,
          |}>,
          +country?: ?string,
          +numRatings?: ?number,
          +avgRatingRounded?: ?any,
          +summary?: ?{|
            +campusCondition: ?number,
            +campusLocation: ?number,
            +careerOpportunities: ?number,
            +clubAndEventActivities: ?number,
            +foodQuality: ?number,
            +internetSpeed: ?number,
            +libraryCondition: ?number,
            +schoolReputation: ?number,
            +schoolSafety: ?number,
            +schoolSatisfaction: ?number,
            +socialActivities: ?number,
          |},
        |},
      |}>,
      +pageInfo: {|
        +hasNextPage: boolean,
        +endCursor: ?string,
      |},
    |}
  |}
|};
export type NewSearchSchoolsQuery = {|
  variables: NewSearchSchoolsQueryVariables,
  response: NewSearchSchoolsQueryResponse,
|};
*/


/*
query NewSearchSchoolsQuery(
  $query: SchoolSearchQuery
  $includeCompare: Boolean!
) {
  newSearch {
    schools(query: $query) {
      edges {
        cursor
        node {
          id
          legacyId
          name
          city
          state
          departments {
            id
            name
          }
          name @include(if: $includeCompare)
  city @include(if: $includeCompare)
  state @include(if: $includeCompare)
  country @include(if: $includeCompare)
  numRatings @include(if: $includeCompare)
  avgRatingRounded @include(if: $includeCompare)
  legacyId @include(if: $includeCompare)
  summary @include(if: $includeCompare) {
            campusCondition
            campusLocation
            careerOpportunities
            clubAndEventActivities
            foodQuality
            internetSpeed
            libraryCondition
            schoolReputation
            schoolSafety
            schoolSatisfaction
            socialActivities
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "query",
    "type": "SchoolSearchQuery",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeCompare",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "newSearch",
    "storageKey": null,
    "args": null,
    "concreteType": "newSearch",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "schools",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "query",
            "variableName": "query"
          }
        ],
        "concreteType": "SchoolSearchConnectionConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "SchoolSearchConnectionEdge",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "School",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "legacyId",
                    "args": null,
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "city",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "state",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "departments",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Department",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/)
                    ]
                  },
                  {
                    "kind": "Condition",
                    "passingValue": true,
                    "condition": "includeCompare",
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "country",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "numRatings",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "avgRatingRounded",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "summary",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "SchoolSummary",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "campusCondition",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "campusLocation",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "careerOpportunities",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "clubAndEventActivities",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "foodQuality",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "internetSpeed",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "libraryCondition",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "schoolReputation",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "schoolSafety",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "schoolSatisfaction",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "socialActivities",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "NewSearchSchoolsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "NewSearchSchoolsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "NewSearchSchoolsQuery",
    "id": null,
    "text": "query NewSearchSchoolsQuery(\n  $query: SchoolSearchQuery\n  $includeCompare: Boolean!\n) {\n  newSearch {\n    schools(query: $query) {\n      edges {\n        cursor\n        node {\n          id\n          legacyId\n          name\n          city\n          state\n          departments {\n            id\n            name\n          }\n          name @include(if: $includeCompare)\n  city @include(if: $includeCompare)\n  state @include(if: $includeCompare)\n  country @include(if: $includeCompare)\n  numRatings @include(if: $includeCompare)\n  avgRatingRounded @include(if: $includeCompare)\n  legacyId @include(if: $includeCompare)\n  summary @include(if: $includeCompare) {\n            campusCondition\n            campusLocation\n            careerOpportunities\n            clubAndEventActivities\n            foodQuality\n            internetSpeed\n            libraryCondition\n            schoolReputation\n            schoolSafety\n            schoolSatisfaction\n            socialActivities\n          }\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c9c52f807083937759e3e3337bfea413';
module.exports = node;
