import React from 'react'
import PropTypes from 'prop-types'
import { createFragmentContainer, graphql } from 'react-relay'
import { generatePath } from 'react-router-dom'
import SchoolTypes from '@types/School.types'
import ROUTE from '@enums/ROUTE'
import useGlobalContext from '@hooks/useGlobalContext'
import MODALS from '@enums/MODALS'
import isMaintenanceMode from '@utils/isMaintenanceMode'
import Link from './Link'

export function RateSchoolLink({ school, trackingLabel, className, children, id }) {
  const { setActiveModal } = useGlobalContext()
  const maintenanceMode = isMaintenanceMode()

  const onClick = e => {
    if (maintenanceMode) {
      e.preventDefault()
      setActiveModal(MODALS.MAINTENANCE_ANNOUNCEMENT)
    }
  }
  const addSchoolLink = generatePath(ROUTE.NEW_ADD_RATING_SCHOOL, { sid: school.legacyId })

  return (
    <Link
      className={className}
      to={addSchoolLink}
      trackingLabel={trackingLabel}
      onClick={onClick}
      id={id}
    >
      {children}
    </Link>
  )
}

export default createFragmentContainer(RateSchoolLink, {
  school: graphql`
    fragment RateSchoolLink_school on School {
      legacyId
    }
  `
})

RateSchoolLink.propTypes = {
  school: SchoolTypes.isRequired,
  trackingLabel: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string
}
