/**
 * @flow
 * @relayHash 2a65136f4440837b7c37c89e0acbcbcc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type FormController_validation$ref = any;
export type ProfessorInputObject = {|
  teacherId?: ?string,
  firstName: string,
  lastName: string,
  email: string,
  password?: ?string,
|};
export type ProfessorSignUpModalMutationVariables = {|
  professor?: ?ProfessorInputObject
|};
export type ProfessorSignUpModalMutationResponse = {|
  +createProfessor: ?{|
    +validation: ?{|
      +$fragmentRefs: FormController_validation$ref
    |}
  |}
|};
export type ProfessorSignUpModalMutation = {|
  variables: ProfessorSignUpModalMutationVariables,
  response: ProfessorSignUpModalMutationResponse,
|};
*/


/*
mutation ProfessorSignUpModalMutation(
  $professor: ProfessorInputObject
) {
  createProfessor(professor: $professor) {
    validation {
      ...FormController_validation
      id
    }
  }
}

fragment FormController_validation on ValidationResult {
  id
  success
  errors {
    field
    messages
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "professor",
    "type": "ProfessorInputObject",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "professor",
    "variableName": "professor"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ProfessorSignUpModalMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createProfessor",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UserMutationResponse",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "validation",
            "storageKey": null,
            "args": null,
            "concreteType": "ValidationResult",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "FormController_validation",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ProfessorSignUpModalMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createProfessor",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UserMutationResponse",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "validation",
            "storageKey": null,
            "args": null,
            "concreteType": "ValidationResult",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "success",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "errors",
                "storageKey": null,
                "args": null,
                "concreteType": "FieldErrorType",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "field",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "messages",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "ProfessorSignUpModalMutation",
    "id": null,
    "text": "mutation ProfessorSignUpModalMutation(\n  $professor: ProfessorInputObject\n) {\n  createProfessor(professor: $professor) {\n    validation {\n      ...FormController_validation\n      id\n    }\n  }\n}\n\nfragment FormController_validation on ValidationResult {\n  id\n  success\n  errors {\n    field\n    messages\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7911d3d2501808e82bf3512bbc146cc8';
module.exports = node;
