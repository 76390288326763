import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import SEARCH_ITEM_TYPES from '@enums/SEARCHITEMTYPES'
import SEARCH_CATEGORY from '@enums/SEARCH_CATEGORY'
import { DebouncedSearchInput } from '@components/Search'
import { TextFieldStyles } from '@StyledComponents/TextField'
import FormContext from '@contexts/FormContext'
import LABEL_STYLE, { LabelStyleTypes } from '@enums/LABEL_STYLE'
import InputWrapper from '@components/StyledComponents/InputWrapper'
import SearchContainer from '@components/Search/SearchContainer'
import FieldWrapper from './FieldWrapper'

const FormSearch = styled(SearchContainer)`
  max-width: ${props => (props.inModal ? 'unset' : '360px')};
  width: 100%;
  ${DebouncedSearchInput} {
    ${props => (props.styles ? props.styles : TextFieldStyles)}
  }
`

const buildOnSchoolSelected = setSelectedSchool => ({ event, itemType, item, setIsOpen }) => {
  /**
   * We are preventing clicking here so that the item value can be selected for the form
   */
  event.preventDefault()
  if (itemType !== SEARCH_ITEM_TYPES.SCHOOLS) return
  /**
   * Once the item is selected, we update the form model with setSelectedSchool
   * and then we set the value of inputValue to be the name of the school. While an extra search
   * query will be triggered (for the recently updated inputValue), it's expected and allows us to
   * minimize the amount of variables we are passing between search components
   */
  setSelectedSchool(item)
  setIsOpen(false)
}

/**
 * FormSchoolSelector is a General Form Solution school typeahead field for
 * picking schools. It's a bit quirky, the `name` should be the key the entire
 * school object is under in the existingData, and the output in the model in
 * your getVariables function will be the schoolId, keyed with the same name.
 */
const FormSchoolSelector = ({
  disabled,
  name,
  className,
  label,
  labelStyle = LABEL_STYLE.LEFT,
  inModal,
  addNewProf,
  styles,
  compareSchools
}) => {
  const { locked, model, onChange } = useContext(FormContext)
  const school = model?.[name]
  const [isFocused, setFocus] = useState(false)
  const setSelectedSchool = value => onChange({ target: { value, name } })
  const [hasValue, setHasValue] = useState()
  const onSchoolSelected = buildOnSchoolSelected(setSelectedSchool)

  return (
    <FieldWrapper fieldName={name} className={className}>
      <InputWrapper
        isFocused={isFocused}
        setFocus={setFocus}
        hasValue={hasValue}
        setHasValue={setHasValue}
        name={name}
        label={label}
        labelStyle={labelStyle}
        className={className}
      >
        {/* hidden input for form value for submit */}
        {school && (
          <React.Fragment>
            <input
              type="text"
              name={name}
              aria-hidden="true"
              hidden
              value={school.id}
              onChange={onChange}
            />
            {addNewProf && (
              <input
                type="text"
                name="schoolName"
                aria-hidden="true"
                hidden
                value={school.name}
                onChange={onChange}
              />
            )}
          </React.Fragment>
        )}
        <FormSearch
          searchCategory={SEARCH_CATEGORY.SCHOOLS}
          disabled={locked || disabled}
          allowRedirect={false}
          onItemSelected={onSchoolSelected}
          onKeyDown={e => {
            if (e.keyCode >= 65 && e.keyCode <= 90) {
              setSelectedSchool(null)
            }
          }}
          inputValue={school?.name || ''}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          inModal={inModal}
          hidePlaceholder
          styles={styles}
          compareSchools={compareSchools}
          isComparePage={compareSchools}
          hideLink
          useErrorBorder
        />
      </InputWrapper>
    </FieldWrapper>
  )
}

FormSchoolSelector.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelStyle: LabelStyleTypes,
  inModal: PropTypes.bool,
  addNewProf: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  styles: PropTypes.object,
  compareSchools: PropTypes.bool
}

export default FormSchoolSelector
