import React, { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { breakpointSmediumAndBelow } from '@components/StyledComponents/theme/helpers/breakpoints'

const StyledJWWrapper = styled.div`
  ${breakpointSmediumAndBelow(css`
    & .jw-flag-floating .jw-wrapper {
      transform: none !important;
      transition: none !important;
      position: fixed !important;
      top: 0 !important;
      right: 0 !important;
      left: auto !important;
      width: 100% !important;
      max-width: 264px !important;
      max-height: 164px !important;

      pointer-events: none;
      user-select: none;
      touch-action: none !important;
    }

    & .jw-float-bar,
    & .jw-float-icon {
      display: none !important;
    }
  `)}
`

const StyledJWPlayer = styled.div`
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.videoAnchor};
`

const JWVideoPlayer = () => {
  const playerRef = useRef(null)

  useEffect(() => {
    const scriptId = 'rmp-jwplayer-script'

    if (!document.getElementById(scriptId)) {
      const script = document.createElement('script')
      script.id = scriptId
      script.src = 'https://cdn.jwplayer.com/libraries/QSNV25sR.js'
      script.async = true
      document.body.appendChild(script)

      script.onload = () => {
        if (window.jwplayer) {
          window.jwplayer(playerRef.current.id).setup({
            aspectratio: '16:9',
            playlist: 'https://cdn.jwplayer.com/v2/playlists/a43LhOp6?format=json'
          })
        }
      }
    } else if (window.jwplayer) {
      window.jwplayer(playerRef.current.id).setup({
        aspectratio: '16:9',
        playlist: 'https://cdn.jwplayer.com/v2/playlists/a43LhOp6?format=json'
      })
    }

    return () => {
      if (window.jwplayer && playerRef.current) {
        window.jwplayer(playerRef.current.id).remove()
      }
    }
  }, [])

  return (
    <StyledJWWrapper>
      <StyledJWPlayer id="rmp-jwplayer-container" ref={playerRef} />
    </StyledJWWrapper>
  )
}

export default JWVideoPlayer
