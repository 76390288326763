import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { withRouter, useHistory } from 'react-router-dom'
import RedirectWithStatus from '@components/RedirectWithStatus'
import SchoolTypes from '@types/School.types'
import TRACKING_EVENT from '@enums/TRACKING_EVENT'
import ROUTE from '@enums/ROUTE'
import {
  breakpointSmediumAndBelow,
  getPageContentMaxWidth
} from '@components/StyledComponents/theme/helpers/breakpoints'
import PageWrapper from '@components/PageWrapper'
import StickyHeader from '@components/StickyHeader/StickyHeader'
import SchoolSummary from '@components/SchoolSummary/SchoolSummary'
import AdRail from '@components/Ads/AdRail'
import NoRatingsArea from '@components/StyledComponents/NoRatingsArea'
import JWVideoPlayer from '@components/Ads/JWVideoPlayer'
import AdLeaderboard from '@components/Ads/AdLeaderboard'
import SEOMetaInfo from '@components/SEOMetaInfo'
import OutbrainAd from '@components/Ads/OutbrainAd'
import OverallRating from './OverallRating'
import SchoolRatingsContainer from './SchoolRatingsContainer'
import { getCountryName } from './SchoolRatings.utils'

const AD_TOP_SCHOOL_PAGE = 286

const MainInfoContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-top: 16px;
  ${breakpointSmediumAndBelow(css`
    flex-direction: column;
  `)}
  ${({ theme }) => getPageContentMaxWidth(theme)};
`

const StyledAdLeaderboard = styled(AdLeaderboard)`
  display: none !important;
  ${breakpointSmediumAndBelow(css`
    display: block !important;
  `)}
`

const StyledJWVideoPlayerContainer = styled.div`
  margin-top: 8px;
  margin-left: 24px;
  margin-bottom: 24px;
  max-width: 464px;

  ${breakpointSmediumAndBelow(css`
    margin-left: 16px;
    margin-right: 16px;
  `)}
`
export function SchoolRatings({ school }) {
  const [adTargeting, setAdTargeting] = useState()
  const history = useHistory()
  useEffect(() => {
    if (!school) return
    setAdTargeting({
      school_name: school.name,
      school_city: school.city,
      school_state: school.state,
      school_country: getCountryName(school.country)
    })
  }, [school])

  if (school === undefined) {
    return <PageWrapper>Loading...</PageWrapper>
  }

  if (school === null || !school.legacyId) {
    return <RedirectWithStatus to={ROUTE.CAMPUS_NOT_FOUND} status={301} />
  }

  return (
    <React.Fragment>
      <SEOMetaInfo
        title={`${school.name} | Rate My Professors`}
        description={`See what students are saying about ${school.name} or leave a rating yourself.`}
      />
      <StickyHeader school={school} teacher={null} large />
      <PageWrapper>
        {school.numRatings === 0 ? (
          <NoRatingsArea
            school={school}
            trackingLabel={TRACKING_EVENT.SCHOOL_RATE_BUTTON_BOTTOM}
            gray
          />
        ) : (
          <React.Fragment>
            <StyledJWVideoPlayerContainer>
              <JWVideoPlayer />
            </StyledJWVideoPlayerContainer>
            <MainInfoContainer>
              <OverallRating school={school} />
              <SchoolSummary school={school} />
            </MainInfoContainer>
            <StyledAdLeaderboard code="ad_lb_top_ratings" onlyMobile />
            <AdRail adTop={AD_TOP_SCHOOL_PAGE} adTargetingSchools={adTargeting} />
            <SchoolRatingsContainer school={school} />
            <OutbrainAd history={history} />
          </React.Fragment>
        )}
      </PageWrapper>
    </React.Fragment>
  )
}

SchoolRatings.propTypes = {
  school: SchoolTypes
}

export default withRouter(SchoolRatings)
